<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3">Modification Marque</h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxEditMarque"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Information Marque</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <H5 class="form-label">Nom </H5>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom Marque"
                  v-model="selectedMarqueInfo.name"
                />
                <label
                  >Le nom est la façon dont il apparaît sur votre site.</label
                >
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <H5 class="form-label">Slug </H5>
                  </div>

                  <input
                    class="form-control"
                    placeholder="SLUG"
                    v-model="selectedMarqueInfo.slug"
                  />

                  <label
                    >Le « slug » est la version du nom normalisée pour les
                    URL.</label
                  >
                </div>
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <h5 class="form-label">Marque Parent</h5>
                    <feather-icon
                      style="color: #19b4bf; cursor: pointer"
                      icon="RefreshCcwIcon"
                      size="20"
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      v-b-tooltip.hover.v-dark
                      title="Actualiser liste des marques"
                      variant="gradient-primary"
                    />
                  </div>
                  <v-select
                    style="cursor: pointer"
                    label="name"
                    :options="listeMarques.marques"
                    :reduce="(selectedMarqueInfo) => selectedMarqueInfo.id"
                    v-model="selectedMarqueInfo.parent"
                    :loading="marqueLoader"
                    :disabled="selectedMarqueInfo.parent === 0"
                  />
                  <label
                    >Assignez un terme parent pour créer une hiérarchie.</label
                  >
                </div>
              </div>

              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="selectedMarqueInfo.description" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-12 col-lg-1">
                  <div>
                    <feather-icon
                      style="color: #19b4bf; cursor: pointer"
                      icon="InfoIcon"
                      size="20"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-11">
                  <label style="font-weight: bold">
                    Pour modifier une image, veuillez supprimer l'image
                    existante puis re-uploader une nouvelle, merci
                  </label>
                </div>
              </div>
              <div class="row mb-3">
                <b-overlay
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                  :show="imageLoader"
                >
                  <vue-upload-multiple-image
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :max-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    v-model="imageMarque"
                    :data-images="imageMarque"
                    @before-remove="beforeRemove"
                    @upload-success="uploadImageAndReturnSrc"
                    :showEdit="false"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { BOverlay, VBTooltip } from "bootstrap-vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { uploadImageSuccess } from "../../views/Products/ProductSettings/FileUpload.js";
import axios from "axios";
import websiteURL from "@/websiteURL";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { getListeMarque } from "../../views/Marques/MarqueFunction";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BOverlay,
    VBTooltip,
    quillEditor,
    vSelect,
    VueUploadMultipleImage,
  },
  data() {
    return {
      imageLoader: false,
      loading: false,
      selectedMarqueInfo: [],
      listeMarques: [],
      marqueLoader: false,
      imageMarque: [],
      image: {
        src: "",
      },
      boxMsgEditMarque: "",
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl =
      websiteURL.WEBSITE + `wp-json/wc/v3/products/brands/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.selectedMarqueInfo = response.data;
        if (vm.selectedMarqueInfo.image) {
          vm.imageMarque = vm.transformImagesUploadToImagesArray(
            vm.selectedMarqueInfo.image
          );
        }
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    transformImagesUploadToImagesArray(imagesUploadArray) {
      const transformedArray = [];

      transformedArray.push({
        name: "",
        path: imagesUploadArray.src,
        highlight: 1,
        default: 1,
      });
      return transformedArray;
    },
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageLoader = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        this.imageMarque = this.transformImagesUploadToImagesArray(
          uploadedUrls[0]
        );
        this.imageLoader = false;
      } catch (error) {
        alert("Une erreur s'est produite lors de l'upload de l'image", error);
      }
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Voulez vous vraiment supprimer l'image ?");
      if (r == true) {
        this.imageMarque = [];
        done();
      } else {
      }
    },
    //Permet de modifier les informations d'une marque
    async editMarque() {
      if (this.imageMarque.length > 0) {
        this.image.src = this.imageMarque[0].path;
      }
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/brands/${this.selectedMarqueInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          name: this.selectedMarqueInfo.name,
          description: this.selectedMarqueInfo.description,
          slug: this.selectedMarqueInfo.slug,
          parent: this.selectedMarqueInfo.parent,
          image: this.image,
        };

        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.editMarqueConfirmMsg("info");
      } catch (error) {
        alert(
          "Une erreur s'est produite lors de la modification de la marque",
          error
        );
      }
    },
    //Boite dialogue pour confirmer ou non la modification de la marque
    showMsgBoxEditMarque() {
      this.boxMsgEditMarque = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir mettre à jour la marque ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgEditMarque = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.editMarque().then(() => {
                this.loading = false;
              });
            }
          }
        });
    },
    //Message indiquant que la modification à bien était effectuée
    editMarqueConfirmMsg(variant = null) {
      this.$bvToast.toast("La marque a bien été mise à jour", {
        title: `Marque ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
  async created() {
    this.listeMarques = await getListeMarque();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 150px;
    }
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
